import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ContactMe from "../components/contactMe"
import MainHero from "../components/mainHero"
import SEO from "../components/SEO"
import RenderMarkdown from "../cms/renderMarkdown"

const Service = ({ data }) => {
  const service = data.mdx
  return (
    <Layout>
      <SEO
        title={`${service.frontmatter.title} | MI | Sydney Reiter Therapy`}
      />
      <MainHero
        heroImage={service.frontmatter.hero_image}
        title={service.frontmatter.title}
        subTitle={service.frontmatter.subTitle}
        height="is-medium"
        crop={service.frontmatter.focus}
      />
      <section className="section">
        <div className="container">
          <div
            style={{
              maxWidth: "850px",
              margin: "auto",
            }}
          >
            <RenderMarkdown md={service.body} />
          </div>
        </div>
      </section>
      <ContactMe />
    </Layout>
  )
}

export default Service

export const getPostData = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        subTitle
        slug
        focus
        hero_image {
          childImageSharp {
            sizes(maxWidth: 1920) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
      body
    }
  }
`
